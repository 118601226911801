<template>
	<div>
		<el-select v-model="roleId" placeholder="请选择"><el-option v-for="item in options" :key="item.id" :label="item.name" :value="item.id"></el-option></el-select>
		<div class="btnArea">
			<el-button size="small" @click="handleChangeClose">关 闭</el-button>
			<el-button size="small" type="primary" @click="handleChangeConfirm">确 定</el-button>
		</div>
	</div>
</template>

<script>
export default {
	name: 'bindRole',
	props: {
		selectdata: {
			type: Object,
			default: () => {}
		}
	},
	data() {
		return {
			options: [],
			roleId: ''
		};
	},
	mounted() {
		console.log(this.selectdata);
		this.getRoleList();
	},
	methods: {
		handleChangeClose() {
			this.$emit('schange', false);
		},
		async handleChangeConfirm() {
			if(!this.roleId) return this.$message.warning('请选择角色！');
			await this.$axios('GET', `/sys/sysRole/roleBingUser?roleId=${this.roleId}&userId=${this.selectdata.id}`,{},true);
			this.$message.success('角色绑定成功！');
			this.$emit('fchange', false);
		},
		async getRoleList() {
			const res = await this.$axios('GET', '/sys/sysRole/listAll',{},true);
			this.options = res;
		}
	}
};
</script>

<style lang="scss"></style>
